export default {
  common: {
    account: 'アカウント',
    signOut: 'サインアウト',
    return: '戻る',
    back: '戻る',
    search: '検索',
    colon: '：',
    tilde: '～',
    comma: '，',
    slash: '/',
    parenthesis: '（{{text}}）',
    ratio: '{{num}}%',
    month: '{{which}}月',
    year: '{{which}}年',
    update: '更新',
    delete: '削除',
    detail: '詳細',
    suspend: '中止',
    no: 'いいえ',
    yes: 'はい',
    note: 'メモ',
    deleteAction: '削除する',
    editAction: '編集する',
    register: '登録する',
    close: '閉じる',
    change: '変更',
    notificationMsg: '通知',
    sure: '確認',
    edit: '編集',
    add: '追加',
    unspecified: '指定なし',
    price: '{{price}}円',
    tax: '（{{price}}円）',
    confirm: '確定',
    addNew: '登録',
    cancel: 'キャンセル',
    execute: '実行',
    cancelAction: 'キャンセルする',
    cancellationPolicy: 'キャンセルポリシー',
    valid: '有効',
    invalid: '無効',
    confirmTitle: 'ご確認ください',
    confirmDelete: '{{name}}を削除してもよろしいですか？',
    up: '上り',
    down: '下り',
    numberSeats: '{{number}} 席',
    seat: '席',
    inventoryArrangements: '枠調整',
    bookingStatus: '予約ステータス',
    agentType: '契約区分',
    agentName: '代理店名',
    agentSubGroupName: '営業所名',
    ensureSeats: '席確保',
    status: 'ステータス',
    subStatus: 'サブステータス',
    paymentStatus: '支払ステータス',
    paymentType: '支払い方法',
    whichServiceOrder: '第 {{number}} 希望',
    totalParticipantQuantity: '人数合計',
    total: '合計',
    numPassenger: '{{num}} 名',
    reserveAllotments: '席数確保',
    bookingId: '予約番号',
    bookingContent: '予約内容',
    groupType: '団体区分',
    groupName: '団体名',
    groupNameKana: '団体名(ふりがな)',
    country: '国',
    japanPrefectureCode: 'ご利用者都道府県',
    applicantName: '申込者氏名',
    applicantPhoneNumber: '申込者連絡先',
    applicantEmail: '申込者メール',
    tourConductorName: '添乗員・代表者氏名',
    tourConductorPhoneNumber: '添乗員・代表者連絡先',
    waitListing: 'キャンセル待ち',
    clear: 'クリア',
    submittedAt: '申込日',
    serviceDate: '乗車日時',
    dateOfUse: '乗車日',
    operatorName: '担当者',
    participantQuantity: '人数',
    serviceName: '便名',
    range: '区間',
    rangeSelect: '区間選択',
    statusTipsTitle: '代理店向けステータス',
    agent: '代理店',
    agentSubGroup: '営業所',
    liberateSeatsNumber: '席数解放',
    approval: '承認',
    assignSeats: '席付',
    dateTime: '日時',
    standing: '立席',
    wheelChairs: '車椅子',
    submittedOrders: '受付リスト',
    operation: 'オプレーション',
    invalidName: '名称を入力してください。',
    invalidPositiveNumber: '正の整数を入力してください。',
    invalidNumber: '非負の整数を入力してください。',
    numberExceeds: '最大 {{number}} まで',
    invalidDateRangeTo: '終了日は開始日より後にしてください。',
    invalidDateRangeFrom: '開始日は終了日より前にしてください。',
    invalidTimeRangeTo: '終了時間は開始時間より後にしてください。',
    invalidTimeRangeFrom: '開始時間は終了時間より前にしてください。',
    addNote: '追記',
    confirmAll: '一斉確定',
    confirmAllSuccess: '一斉確定しました。',
    invoice: '請求書',
    view: '参照',
    receipt: '領収書',
    send: '送信',
    issue: '作成',
    pay: '支払',
    creditCardPayment: 'クレジット決済',
    couponPayment: '船車券発行',
    discrepancy: '先月繰越金',
    totalAmount: '総額',
    agentGroupReqPay: '営業所請求/支払',
    payConfirm: '支払確認',
    settlement: '運賃　{{year}}年{{month}}月精算分',
    salesManager: '売上管理',
    agentSubGroupSales: '{{agentSubGroup}}の売上管理一覧',
    bookingList: '予約一覧',
    agentId: '代理店ID',
    redemptionStatus: '利用ステータス'
  },
  layouts: {
    sidebar: {
      seatInquiry: '空席照会/新規予約'
    }
  },
  dashboard: {
    title: 'ダッシュボード',
    message: {
      list: '記事一覧',
      title: 'タイトル',
      contents: 'コンテンツ',
      files: 'ファイル',
      deleteTips: '記事が削除されました。ダッシュボードを再表示してください。',
      agent: '代理店向けコンテンツ',
      station: '社内向けコンテンツ',
      report: 'システムコンテンツ'
    }
  },
  notificationMessage: {
    BOOKING_REQUESTED: '予約が受付けられました',
    MODIFICATION_REQUESTED: '予約変更が受付けられました',
    STATUS_UPDATED: '予約ステータスが変更されました',
    COUPON_INVOICE_RECEIVED: '請求書を受領しました。船車券を発行してください',
    NORMAL_INVOICE_RECEIVED: '請求書を受領しました。支払いをお願いします',
    SEAT_TABLE_RECEIVED: '席番表を受領しました',
    NOTE_UPDATED: 'メモが更新されました',
    TOUR_CONDUCTOR_REQUEST_RECEIVED: '添乗員を登録してください',
    RECEIPT_RECEIVED: '領収書を受領しました',
    SAGANO_BOOKING_RECEIVED: '予約を受付けました',
    SAGANO_MODIFICATION_RECEIVED: '予約変更を受付けました',
    SAGANO_CANCELLATION_RECEIVED: 'キャンセル受付',
    SAGANO_REQUEST_ISSUE_INVOICE: '請求書を発行してください',
    SAGANO_PROMPT_REQUEST_PAYMENT: '支払を督促してください',
    SAGANO_REQUEST_ASSIGN_SEAT: '席付を実施してください',
    SAGANO_COUPON_RECEIVED: '船車券を受領しました',
    SAGANO_NOTE_UPDATED: 'メモの更新がありました',
    SAGANO_PAYMENT_RECEIVED: 'クレカ支払がありました',
    SAGANO_TOUR_CONDUCTOR_NOT_YET_REGISTERED: '添乗員が未登録です',
    PLEASE_ISSUE_COUPON: '船車券を発行してください',
    PLEASE_PAY: '支払をお願いします'
  },
  baseCar: {
    front: '運転室',
    entrance: '出入り口'
  },
  carName: {
    car1: '1号車',
    car2: '2号車',
    car3: '3号車',
    car4: '4号車',
    car5: 'リッチ号/5号車'
  },
  agentSubGroupReport: {
    title: '代理店精算管理',
    subTitle: '営業所請求/支払一覧'
  },
  seatInquiry: {
    createTitle: '空席照会/新規予約',
    updateTitle: '乗車日の変更',
    seatPickTips:
      'ご希望のお時間（「<0></0>」「<1></1>」「<2></2>」）を選択して「予約ページへ」を押してください。',
    seatStateTips:
      '<0></0>：団体席あり <1></1>：団体席わずか <2></2>：団体席なし',
    singleTrip: '片道乗車',
    roundTrip: '往復乗車',
    yymm: '指定日時',
    departureStation: '乗車駅',
    arrivalStation: '下車駅',
    groupSeats: '団体席数',
    requestedSeats: '合計必要数',
    vacantSeats: '残数',
    reservedOrOccupiedSeats: '確保済席数',
    confirmedOrders: '確定リスト',
    cancellationSubmittedOrders: 'キャンセル受付リスト',
    adminTag: {
      HAS_BOOKING: '予約あり',
      GROUP_ARRANGEMENT_INSUFFICIENT: '団体席不足',
      HAS_WAITLISTING_BOOKING: 'キャンセル待ちあり'
    },
    byDate: {
      bookingStatus: '{{date}}の予約状況',
      serviceName: '列車名',
      selectTrain: '予約する列車を 選択',
      groupSeatsNumber: '団体席数',
      goToCreate: '予約内容入力へ',
      goToUpdate: '予約変更画面へ',
      up: '【上り】トロッコ{{fromStationLabel}}→トロッコ{{toStationLabel}}',
      down: '【下り】トロッコ{{fromStationLabel}}→トロッコ{{toStationLabel}}'
    }
  },
  notification: {
    title: '通知一覧',
    booking: '予約',
    payment: '請求',
    deleteSuccess: '通知を削除しました。'
  },
  sales: {
    handleFee: '運賃請求/支払',
    handleFareTitle: '請求/支払処理',
    handleDiscrepancyTitle: '差額請求/支払処理',
    normalDueDate: '運賃支払期限',
    discrepancyDueDate: '差額支払期限',
    handleDiscrepancy: '差額請求/支払',
    totalDiscrepancy: '差額合計',
    monthlyInvoiceId: '月極請求'
  },
  monthlyReport: {
    title: '月極精算管理',
    month: '精算月',
    confirmPayMessage: '支払を確認済にします',
    confirmPayTip: '実行後は元に戻せません。よろしいですか？'
  },
  booking: {
    wheelChairUse: '車椅子利用',
    needChair: '車椅子席をご利用の方',
    needSlope: 'スロープを利用する',

    numberThOfChair: '{{number}} 人目',
    numberOfChair: '{{number}} 台',
    useWheelChairInfo:
      'ご利用台数 {{total}} 台（スロープ利用 {{needSlope}} 台）',
    fare: '運賃',
    typeFare: '運賃内訳',
    totalFare: '運賃合計',
    reductionQuantity: '累積減員数',
    reductionHandleCharge: '減員手数料',
    totalFareNum: '運賃合計(1)',
    decrementFeeTotal: '減員手数料合計(2)',
    totalNum: '総額(1)+(2)',
    cancelFeeTotal: 'キャンセル手数料(3)',
    scheduledPayment: 'お支払い予定額',
    totalPaymentText: '合計 ',
    totalPaymentPrice: '{{totalPrice}}円',
    totalPayment: '合計 {{totalPrice}}円',
    passengerNumber: '乗車人数',
    provisionalBreakdownOfCharges: '仮料金内訳',
    requestBooking: 'リクエスト予約をする',
    saganoMessageInnerNote: '嵯峨野トロッコ内部メモ',
    saganoMessageNote: '嵯峨野様 通信メモ',
    agentMessageNote: '代理店様 通信メモ',
    history: '変更履歴',
    stationMessageNote: '{{name}}トロッコメモ',
    applyWaitlist: '申し込む',
    notApplyWaitlist: '申し込まない',
    sameDay: '（同じ日）',
    fromCalendar: '（カレンダーから選ぶ）',
    checking: '参照',
    autoCalc: '自動計算',
    manualCalc: '手動計算',
    fareAndReductionFee: '運賃＋減員手数料合計',
    agentFee: '代理店手数料',
    totalPaymentAmount: '精算額',
    paymentStatus: '支払ステータス',
    agentSettlementId: '代理店請求ID',
    agentSubGroupSettlementId: '営業所請求ID',
    paymentMethod: '支払方法',
    cancellationFee: 'キャンセル手数料（キャンセル手数料＋累積減員手数料合計）',
    feeDetail: '料金詳細',
    detail: {
      changeStationSuccess: '区間変更が成功しました。'
    }
  },
  bookingCreate: {
    title: '新規予約',
    copyTitle: '予約の複製',
    selectAnotherServiceOrder: '第 {{number}} 希望を選択する',
    wish: '希望する',
    goToConfirm: '予約確認画面へ',
    needChair: '車椅子席をご利用の方',
    canWalk: '歩行可能な方',
    walkingIsDifficult: '歩行が難しい方'
  },
  bookingCancel: {
    title: '予約キャンセル',
    cancelConfirm: '下記の予約を解約（払戻）しますか？',
    paied: '支払済',
    requestAmount: '請求額',
    refundAmount: '返金額',
    cancelNote: '※健常者運賃×料率＋累積減員手数料（健常者・障害者）',
    reductionNumber: '課金減員数',
    totalReduction: '減員数合計',
    handleCharge: '手数料',
    reductionCharge: '減員手数料合計',
    cancelCharge: 'キャンセル料',
    cancelChargeRatio: 'キャンセル料率',
    cancelAndReductionCharge: 'キャンセル料 + 減員手数料合計',
    confirm: 'キャンセルを確定する',
    applyCancelSuccess: 'キャンセルを申請しました。',
    confirmCancelSuccess: 'キャンセルを確定しました。',
    cancelConfirmText:
      'キャンセルチャージ料金{{amount}}円です。\n弊社が承諾して、はじめて取消完了となります。\nキャンセル実行後の操作取消できません。',
    apply: 'キャンセルを申請する'
  },
  bookingDetail: {
    title: '予約詳細',
    modifyBooking: '予約内容変更',
    copyBooking: '予約内容の複製',
    pdfDownload: 'PDFダウンロード',
    cancelBooking: '予約キャンセル',
    showVoucher: '席番表（電子チケット）の表示',
    history: {
      type: '処理',
      date: '日付',
      detail: 'ページ詳細'
    },
    cancelTitle: '予約をキャンセルしますか？',
    cancelNote:
      '予約キャンセルには、<href>{{cancellationPolicy}}</href>に同意して頂く必要があります'
  },
  bookingConfirm: {
    title: '予約確認',
    cancelNote:
      '予約をするには、<href>{{cancellationPolicy}}</href>に同意して頂く必要があります'
  },
  bookingUpdate: {
    title: '予約の変更',
    updateServiceOrder: '列車変更（一度だけ変更可）',
    updateParticipantGroup: '人数変更'
  },
  bookingHistory: {
    title: '予約受付内容'
  },
  fareTypeId: {
    adult: '大人',
    child: '小人'
  },
  directions: {
    down: '下り',
    up: '上り'
  },
  agentStaffType: {
    ADMIN: '管理者',
    STAFF: '担当者'
  },
  agentPaymentType: {
    PREPAID: '前払クレカ',
    COUPON: '船車券',
    MONTHLY: '後払銀振',
    CASH: '現金',
    OTHER: 'その他'
  },
  bookingHistoryType: {
    CREATE: '予約受付',
    UPDATE: '予約変更受付',
    CANCEL: 'キャンセル受付'
  },
  paymentStatus: {
    INITED: '未確定',
    INVOICE_PENDING: '請求書未発行',
    PAYMENT_PENDING: '支払待ち',
    PAYMENT_CONFIRMED: '支払済',
    COUPON_CONFIRMED: '船車券発行済',
    NO_NEED_TO_PAY: '支払不要'
  },
  paymentType: {
    ONLINE_CREDIT_CARD: '前払クレカ',
    COUPON: '船車券'
  },
  paymentStatusExplanation: {
    INITED: '予約受付/予約変更受付状態で請求額が未確定の状態',
    INVOICE_PENDING: '予約が確定し請求額が確定したが、請求書が未発行の状態',
    PAYMENT_PENDING: '請求書を発行し、代理店からの支払を待っている状態',
    COUPON_CONFIRMED: '契約代理店から船車券が発行された状態',
    PAYMENT_CONFIRMED: '非契約代理店からクレジットカードで決済された状態',
    NO_NEED_TO_PAY: '予約NG、予約キャンセル&キャンセル料ゼロ円の場合は支払不要'
  },
  lang: {
    en: '英語',
    ja: '日本語',
    zh: '中国語(簡体)',
    zt: '中国語(繁体)',
    ko: '韓国語'
  },
  agents: {
    title: '代理店一覧',
    agent: '代理店',
    nameJa: '代理店名',
    email: 'メールアドレス（ログインID）',
    phone: '担当者連絡先',
    name: '担当者名',
    mainAdminName: '代理店管理者氏名',
    mainAdminEmail: '代理店管理者メールアドレス (ログインID)',
    updateTitle: '代理店管理: 編集',
    addAgent: '代理店を新規追加',
    addSuccess: '代理店を新規追加しました。',
    sendPasswordResetEmail: 'パスワードリセットメール送信',
    sendPasswordResetEmailSuccess: 'パスワードリセットメールを送信しました。',
    updateSuccess: '代理店情報を更新しました。',
    deleteSuccess: '代理店を削除しました。',
    filterPlaceholder: '代理店・営業所・担当者名',
    detail: {
      title: '代理店管理',
      nameJa: '代理店名',
      nameKana: '代理店名（フリガナ）',
      rootGroupFax: 'FAX番号',
      rootGroupMainAdminId: '代表管理者',
      rootGroupMainAdminName: '代表管理者所属・氏名',
      rootGroupMainAdminEmail: '代表管理者メールアドレス (ログインID)',
      rootGroupMainAdminTel: '代表管理者電話番号',
      countryCode: '国',
      agentType: '契約区分',
      billingDay: '月の締め日',
      paymentDay: '支払日',
      dateEachMonth: '毎月{{date}}日',
      ignoreCancellationPolicy: 'キャンセルチャージ免除',
      monthEnd: '月末',
      dateSpecified: '指定日',
      commission: '手数料'
    },
    staff: {
      title: '代理店担当者一覧',
      name: '担当者所属・氏名',
      email: 'メールアドレス (ログインID)',
      tel: '電話番号',
      languageCode: '言語',
      staffType: '権限',
      addTitle: '担当者を新規追加',
      editTitle: '担当者を編集',
      addSuccess: '代理店の担当者を新規追加しました。',
      updateSuccess: '代理店の担当者を更新しました。',
      deleteSuccess: '代理店の担当者を削除しました。'
    },
    group: {
      title: '営業所一覧',
      staffTitle: '営業所担当者一覧',
      editTitle: '営業所を編集',
      id: '営業所ID',
      nameJa: '営業所名',
      nameOrigin: '営業所名',
      nameKana: '営業所名（フリガナ)',
      email: 'メールアドレス (ログインID)',
      fax: 'FAX番号',
      mainAdminId: '代表管理者',
      mainAdminName: '代表管理者所属・氏名',
      mainAdminEmail: '代表管理者メールアドレス (ログインID)',
      mainAdminTel: '代表管理者電話番号',
      tel: '電話番号',
      addNew: '新規追加する',
      addTitle: '営業所を新規追加',
      addStaffPrompt: '営業所の担当者を新規追加',
      createTitle: '営業所: 新規登録',
      detailTitle: '営業所：詳細',
      modifyTitle: '営業所：編集',
      adminName: '代理管理者氏名',
      adminEmail: '代理管理者メールアドレス (ログインID)',
      dateEachMonth: '毎月{{date}}日',
      addSuccess: '営業所を新規追加しました。',
      deleteSuccess: '営業所を削除しました。',
      updateSuccess: '営業所を更新しました。',
      addStaffSuccess: '営業所の担当者を新規追加しました。',
      updateStaffSuccess: '営業所の担当者を更新しました。',
      deleteStaffSuccess: '営業所の担当者を削除しました。'
    }
  },
  payConfirmStatus: {
    PAYMENT_STATUS_PAID: '確認済',
    PAYMENT_STATUS_UNPAID: '確認待ち'
  },
  groupTypeId: {
    NORMAL: '一般団体',
    SCHOOL: '学生団体'
  },
  agentTypeId: {
    FREE: '非契約代理店',
    CONTRACTED: '契約代理店',
    SPECIAL_CONTRACTED: '特約店'
  },
  agentPaymentTypeId: {
    PREPAID: '前払クレカ',
    COUPON: '船車券',
    MONTHLY: '後払銀振'
  },
  statusTypeId: {
    SUBMITTED: '予約受付',
    MODIFICATION_SUBMITTED: '予約変更受付',
    CANCELLATION_SUBMITTED: 'キャンセル受付',
    RESERVED: '予約OK',
    NOT_RESERVED: '予約NG',
    MODIFIED: '予約変更OK',
    NOT_MODIFIED: '予約変更NG',
    CANCELLED: 'キャンセル確定',
    CANCELLATION_PENDING: 'キャンセル処理中',
    WAITLIST: 'キャンセル待ち'
  },
  subStatusTypeId: {
    INITIAL: '初期状態',
    SAGANO_ALLOTMENT_PENDING: '席数確保中',
    SAGANO_ALLOTMENT_CONFIRMED: '席数確保OK',
    SAGANO_ALLOTMENT_NOT_AVAILABLE: '席数確保NG',
    SAGANO_SEAT_ASSIGNING: '席付中',
    SAGANO_SEAT_RESERVED: '席付OK',
    SAGANO_SEAT_NOT_AVAILABLE: '席付NG',
    SAGANO_SEAT_TABLE_SENT: '席番表送付済'
  },
  statusExplanation: {
    SUBMITTED: '代理店から新規予約/予約変更申請を受付けた',
    RESERVED:
      '新規予約/予約変更（席数確保OKまたは席付OK）が確定し、代理店に予約OKを回答した',
    NOT_RESERVED:
      '新規予約/予約変更（席数確保NGまたは席付NG）が確定し、代理店に予約NGを回答した',
    CANCELLATION_SUBMITTED: '代理店から予約キャンセル申請を受け付けた',
    CANCELLED: '代理店がキャンセル料の支払に同意し、キャンセルを確定した',
    WAITLIST: '代理店からキャンセル待ち申請があり、キャンセル待ちを登録した'
  },
  subStatusExplanation: {
    INITIAL: 'サブステータス未確定の状態',
    SAGANO_ALLOTMENT_PENDING:
      '往復の指定がある場合に、一方のみ席数確保できている状態。片道指定の場合は、席数確保できれば席数確保OKに遷移する',
    SAGANO_ALLOTMENT_CONFIRMED:
      '席数が確保できている状態。往復の場合は両方とも席数が確保できていることを示す',
    SAGANO_ALLOTMENT_NOT_AVAILABLE:
      '席数が確保できなかった。確保中のリソース（例えば、片道のみの席数）は解放する必要がある。確保中のリソースがある場合、システムは警告を発する',
    SAGANO_SEAT_ASSIGNING:
      '往復の指定がある場合に、一方飲み席付けできている状態。片道指定の場合は、席付けできれば席付OKに遷移する',
    SAGANO_SEAT_RESERVED:
      '席付ができている状態。往復の場合は両方とも席付けできていることを示す',
    SAGANO_SEAT_NOT_AVAILABLE:
      '席付け失敗した。確保中のリソース（例えば片道分の席）は解放する必要がある。',
    SAGANO_SEAT_TABLE_SENT:
      '（席付OK後）席番表を送付した。往復指定の場合、両方の席番表を送付済であることを示す'
  },
  unitAnnotationId: {
    NORMAL: '一般',
    HANDICAPPED_1: '障害者1種',
    HANDICAPPED_2: '障害者2種',
    SUPPORTER: '介護者',
    FREE: '無賃'
  },
  login: {
    email: 'メールアドレス',
    password: 'パスワード',
    signIn: 'ログイン',
    forgotPassword: 'パスワードをお忘れの場合',
    invalidLogin: '{{email}}はログインできません。'
  },
  password: {
    changePassword: 'パスワード変更',
    resetPassword: 'パスワード再設定',
    sendResetEmail: 'パスワードリセットメールを送信する',
    sendResetEmailSuccess:
      'パスワード再設定用のURLが{{email}}に送信されます、ご確認のうえパスワードをリセットしてください。',
    oldPassword: '以前のパスワード',
    newPassword: '新しいパスワード',
    changePasswordSuccess: 'パスワードを変更しました。'
  },
  firebase: {
    'auth/wrong-password': 'ログイン名またはパスワードが間違っています。',
    'auth/user-not-found': 'ログイン名またはパスワードが間違っています。',
    'auth/email-already-in-use': 'メールアドレスは既に登録されています。',
    'auth/too-many-requests':
      'ログイン試行の回数が上限を超えました。しばらくしてからもう一度お試しいただくか、パスワードをリセットしてください。'
  },
  validation: {
    required: '{{name}}を入力してください',
    requiredSelect: '選択してください',
    invalid: '有効な{{name}}を入力してください',
    password: '大文字、小文字、記号、数字を組み合わせた8文字以上で作成ください',
    number: '半角数字を入力してください'
  },
  countries: {
    UNI: 'その他',
    ABW: 'アルバ',
    AFG: 'アフガニスタン',
    AGO: 'アンゴラ',
    AIA: 'アンギラ',
    ALA: 'オーランド諸島',
    ALB: 'アルバニア',
    AND: 'アンドラ',
    ARE: 'アラブ首長国連邦',
    ARG: 'アルゼンチン',
    ARM: 'アルメニア',
    ASM: 'アメリカ領サモア',
    ATA: '南極',
    ATF: 'フランス領南方・南極地域',
    ATG: 'アンティグア・バーブーダ',
    AUS: 'オーストラリア',
    AUT: 'オーストリア',
    AZE: 'アゼルバイジャン',
    BDI: 'ブルンジ',
    BEL: 'ベルギー',
    BEN: 'ベナン',
    BES: 'ボネール、シント・ユースタティウスおよびサバ',
    BFA: 'ブルキナファソ',
    BGD: 'バングラデシュ',
    BGR: 'ブルガリア',
    BHR: 'バーレーン',
    BHS: 'バハマ',
    BIH: 'ボスニア・ヘルツェゴビナ',
    BLM: 'サン・バルテルミー',
    BLR: 'ベラルーシ',
    BLZ: 'ベリーズ',
    BMU: 'バミューダ',
    BOL: 'ボリビア多民族国',
    BRA: 'ブラジル',
    BRB: 'バルバドス',
    BRN: 'ブルネイ・ダルサラーム',
    BTN: 'ブータン',
    BVT: 'ブーベ島',
    BWA: 'ボツワナ',
    CAF: '中央アフリカ共和国',
    CAN: 'カナダ',
    CCK: 'ココス（キーリング）諸島',
    CHE: 'スイス',
    CHL: 'チリ',
    CHN: '中華人民共和国',
    CIV: 'コートジボワール',
    CMR: 'カメルーン',
    COD: 'コンゴ民主共和国',
    COG: 'コンゴ共和国',
    COK: 'クック諸島',
    COL: 'コロンビア',
    COM: 'コモロ',
    CPV: 'カーボベルデ',
    CRI: 'コスタリカ',
    CUB: 'キューバ',
    CUW: 'キュラソー',
    CXR: 'クリスマス島',
    CYM: 'ケイマン諸島',
    CYP: 'キプロス',
    CZE: 'チェコ',
    DEU: 'ドイツ',
    DJI: 'ジブチ',
    DMA: 'ドミニカ国',
    DNK: 'デンマーク',
    DOM: 'ドミニカ共和国',
    DZA: 'アルジェリア',
    ECU: 'エクアドル',
    EGY: 'エジプト',
    ERI: 'エリトリア',
    ESH: '西サハラ',
    ESP: 'スペイン',
    EST: 'エストニア',
    ETH: 'エチオピア',
    FIN: 'フィンランド',
    FJI: 'フィジー',
    FLK: 'フォークランド（マルビナス）諸島',
    FRA: 'フランス',
    FRO: 'フェロー諸島',
    FSM: 'ミクロネシア連邦',
    GAB: 'ガボン',
    GBR: 'イギリス',
    GEO: 'ジョージア',
    GGY: 'ガーンジー',
    GHA: 'ガーナ',
    GIB: 'ジブラルタル',
    GIN: 'ギニア',
    GLP: 'グアドループ',
    GMB: 'ガンビア',
    GNB: 'ギニアビサウ',
    GNQ: '赤道ギニア',
    GRC: 'ギリシャ',
    GRD: 'グレナダ',
    GRL: 'グリーンランド',
    GTM: 'グアテマラ',
    GUF: 'フランス領ギアナ',
    GUM: 'グアム',
    GUY: 'ガイアナ',
    HKG: '香港',
    HMD: 'ハード島とマクドナルド諸島',
    HND: 'ホンジュラス',
    HRV: 'クロアチア',
    HTI: 'ハイチ',
    HUN: 'ハンガリー',
    IDN: 'インドネシア',
    IMN: 'マン島',
    IND: 'インド',
    IOT: 'イギリス領インド洋地域',
    IRL: 'アイルランド',
    IRN: 'イラン・イスラム共和国',
    IRQ: 'イラク',
    ISL: 'アイスランド',
    ISR: 'イスラエル',
    ITA: 'イタリア',
    JAM: 'ジャマイカ',
    JEY: 'ジャージー',
    JOR: 'ヨルダン',
    JPN: '日本',
    KAZ: 'カザフスタン',
    KEN: 'ケニア',
    KGZ: 'キルギス',
    KHM: 'カンボジア',
    KIR: 'キリバス',
    KNA: 'セントクリストファー・ネイビス',
    KOR: '大韓民国',
    KWT: 'クウェート',
    LAO: 'ラオス人民民主共和国',
    LBN: 'レバノン',
    LBR: 'リベリア',
    LBY: 'リビア',
    LCA: 'セントルシア',
    LIE: 'リヒテンシュタイン',
    LKA: 'スリランカ',
    LSO: 'レソト',
    LTU: 'リトアニア',
    LUX: 'ルクセンブルク',
    LVA: 'ラトビア',
    MAC: 'マカオ',
    MAF: 'サン・マルタン（フランス領）',
    MAR: 'モロッコ',
    MCO: 'モナコ',
    MDA: 'モルドバ共和国',
    MDG: 'マダガスカル',
    MDV: 'モルディブ',
    MEX: 'メキシコ',
    MHL: 'マーシャル諸島',
    MKD: '北マケドニア',
    MLI: 'マリ',
    MLT: 'マルタ',
    MMR: 'ミャンマー',
    MNE: 'モンテネグロ',
    MNG: 'モンゴル',
    MNP: '北マリアナ諸島',
    MOZ: 'モザンビーク',
    MRT: 'モーリタニア',
    MSR: 'モントセラト',
    MTQ: 'マルティニーク',
    MUS: 'モーリシャス',
    MWI: 'マラウイ',
    MYS: 'マレーシア',
    MYT: 'マヨット',
    NAM: 'ナミビア',
    NCL: 'ニューカレドニア',
    NER: 'ニジェール',
    NFK: 'ノーフォーク島',
    NGA: 'ナイジェリア',
    NIC: 'ニカラグア',
    NIU: 'ニウエ',
    NLD: 'オランダ',
    NOR: 'ノルウェー',
    NPL: 'ネパール',
    NRU: 'ナウル',
    NZL: 'ニュージーランド',
    OMN: 'オマーン',
    PAK: 'パキスタン',
    PAN: 'パナマ',
    PCN: 'ピトケアン',
    PER: 'ペルー',
    PHL: 'フィリピン',
    PLW: 'パラオ',
    PNG: 'パプアニューギニア',
    POL: 'ポーランド',
    PRI: 'プエルトリコ',
    PRK: '朝鮮民主主義人民共和国',
    PRT: 'ポルトガル',
    PRY: 'パラグアイ',
    PSE: 'パレスチナ',
    PYF: 'フランス領ポリネシア',
    QAT: 'カタール',
    REU: 'レユニオン',
    ROU: 'ルーマニア',
    RUS: 'ロシア連邦',
    RWA: 'ルワンダ',
    SAU: 'サウジアラビア',
    SDN: 'スーダン',
    SEN: 'セネガル',
    SGP: 'シンガポール',
    SGS: 'サウスジョージア・サウスサンドウィッチ諸島',
    SHN: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
    SJM: 'スヴァールバル諸島およびヤンマイエン島',
    SLB: 'ソロモン諸島',
    SLE: 'シエラレオネ',
    SLV: 'エルサルバドル',
    SMR: 'サンマリノ',
    SOM: 'ソマリア',
    SPM: 'サンピエール島・ミクロン島',
    SRB: 'セルビア',
    SSD: '南スーダン',
    STP: 'サントメ・プリンシペ',
    SUR: 'スリナム',
    SVK: 'スロバキア',
    SVN: 'スロベニア',
    SWE: 'スウェーデン',
    SWZ: 'エスワティニ',
    SXM: 'シント・マールテン（オランダ領）',
    SYC: 'セーシェル',
    SYR: 'シリア・アラブ共和国',
    TCA: 'タークス・カイコス諸島',
    TCD: 'チャド',
    TGO: 'トーゴ',
    THA: 'タイ',
    TJK: 'タジキスタン',
    TKL: 'トケラウ',
    TKM: 'トルクメニスタン',
    TLS: '東ティモール',
    TON: 'トンガ',
    TTO: 'トリニダード・トバゴ',
    TUN: 'チュニジア',
    TUR: 'トルコ',
    TUV: 'ツバル',
    TWN: '台湾',
    TZA: 'タンザニア',
    UGA: 'ウガンダ',
    UKR: 'ウクライナ',
    UMI: '合衆国領有小離島',
    URY: 'ウルグアイ',
    USA: 'アメリカ合衆国',
    UZB: 'ウズベキスタン',
    VAT: 'バチカン市国',
    VCT: 'セントビンセントおよびグレナディーン諸島',
    VEN: 'ベネズエラ・ボリバル共和国',
    VGB: 'イギリス領ヴァージン諸島',
    VIR: 'アメリカ領ヴァージン諸島',
    VNM: 'ベトナム',
    VUT: 'バヌアツ',
    WLF: 'ウォリス・フツナ',
    WSM: 'サモア',
    YEM: 'イエメン',
    ZAF: '南アフリカ',
    ZMB: 'ザンビア',
    ZWE: 'ジンバブエ'
  },
  japanPrefectures: {
    OVERSEA: '海外',
    HOKKAIDO: '北海道',
    AOMORI: '青森県',
    IWATE: '岩手県',
    MIYAGI: '宮城県',
    AKITA: '秋田県',
    YAMAGATA: '山形県',
    FUKUSHIMA: '福島県',
    IBARAKI: '茨城県',
    TOCHIGI: '栃木県',
    GUNMA: '群馬県',
    SAITAMA: '埼玉県',
    CHIBA: '千葉県',
    TOKYO: '東京都',
    KANAGAWA: '神奈川県',
    NIIGATA: '新潟県',
    TOYAMA: '富山県',
    ISHIKAWA: '石川県',
    FUKUI: '福井県',
    YAMANASHI: '山梨県',
    NAGANO: '長野県',
    GIFU: '岐阜県',
    SHIZUOKA: '静岡県',
    AICHI: '愛知県',
    MIE: '三重県',
    SHIGA: '滋賀県',
    KYOTO: '京都府',
    OSAKA: '大阪府',
    HYOGO: '兵庫県',
    NARA: '奈良県',
    WAKAYAMA: '和歌山県',
    TOTTORI: '鳥取県',
    SHIMANE: '島根県',
    OKAYAMA: '岡山県',
    HIROSHIMA: '広島県',
    YAMAGUCHI: '山口県',
    TOKUSHIMA: '徳島県',
    KAGAWA: '香川県',
    EHIME: '愛媛県',
    KOCHI: '高知県',
    FUKUOKA: '福岡県',
    SAGA: '佐賀県',
    NAGASAKI: '長崎県',
    KUMAMOTO: '熊本県',
    OITA: '大分県',
    MIYAZAKI: '宮崎県',
    KAGOSHIMA: '鹿児島県',
    OKINAWA: '沖縄県'
  }
};
